.progress-container {
     width: 100%;
     background-color: #6a6868;
     border-radius: 5px;
     overflow: hidden;
     margin-top: 10px;
   }
   
   .progress-bar {
     height: 20px;
     border-radius: 5px;
   }
   
   .progress-text {
     text-align: center;
     margin-top: 5px;
     font-weight: bold;
     text-transform: capitalize;
   }


   @media (max-width: 400px) {
    .progress-container {
      width: 95%;
      border-radius: 15px;
      margin: 10px auto;
    }
    
    .progress-bar {
      height: 10px;
      border-radius: 5px;
    }
    
    .progress-text {
      margin-top: 0px;
    }
   }

   @media (min-width: 401px) and (max-width: 500px) {
    .progress-container {
      width: 95%;
      border-radius: 15px;
      margin: 10px auto;
    }
    
    .progress-bar {
      height: 10px;
      border-radius: 5px;
    }
    
    .progress-text {
      margin-top: 0px;
    }
   }
   
   