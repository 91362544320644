.pagination-container {
     display: flex;
     justify-content: center;
     align-items: center;
     margin-top: 20px;
   }

   
   .pagination .page-item .page-link {
     color: #007bff;
     background: #fff;
   }
   
   .pagination .page-item.disabled .page-link {
     color: #6c757d;
   }
   
   .pagination .page-item .page-link {
     border-radius: 50px;
     margin: 0 5px;
     padding: 10px 20px;
   }
   
   .pagination .page-item.active .page-link {
     background-color: #007bff;
     border-color: #007bff;
     color: white;
   }
   