/* General styles */
.navbar-brand {
  font-family: "Poppins", sans-serif;
  font-weight: bolder;
}

/* Mobile devices 300px to 400px */
@media (max-width: 400px) {
  .navbar-brand {
    font-size: 16px;
  }
  .dropdown-button {
    font-size: 12px;
  }
  .navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    width: 80vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  .navbar-collapse.show-filters {
    transform: translateX(0);
  }
  /* Basic styles for the sidebar */
.sidebar {
  position: fixed;
  top: 0;
  right: -250px; /* Start off-screen */
  width: 250px;
  height: 100%;
  background-color: #343a40;
  padding: 20px;
  z-index: 1050;
  transition: right 0.3s ease-in-out;
}

/* When the sidebar is open */
.sidebar.open {
  right: 0; /* Slide in */
}

/* Make sure the dropdown buttons fill the sidebar width */
.dropdown-button {
  width: 100%;
}

  .nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
}

/* Mobile devices 400px to 500px */
@media (min-width: 401px) and (max-width: 500px) {
  .navbar-brand {
    font-size: 18px;
  }
  .dropdown-button {
    font-size: 14px;
  }
  .navbar-collapse {
    position: fixed;
    top: 0;
    right: 0;
    width: 80vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }
  .navbar-collapse.show-filters {
    transform: translateX(0);
  }
  .nav {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
  }
}

/* Tablets 500px to 800px */
@media (min-width: 501px) and (max-width: 800px) {
  .navbar-brand {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  .nav {
    flex-direction: column;
    align-items: center;
  }
  .dropdown-button {
    font-size: 16px;
  }
}