.page {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: rgb(2, 0, 36);
  background: linear-gradient(5deg, rgba(2, 0, 36, 1) 0%, rgba(112, 9, 121, 0.9304971988795518) 42%, rgba(0, 212, 255, 1) 100%);
}

.review {
  border: 1px solid #000;
  padding: 10px;
  font-family: Arial, sans-serif;
  background-color: aliceblue;
}

.review h1 {
  font-size: 20px;
  color: #333;
}

.review .rating {
  color: #f4c150;
}

.review p {
  font-size: 14px;
  color: #666;
  margin-top: 10px;
}

.review ul {
  list-style-type: none;
  padding: 0;
}

.review button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

.card-container {
  margin-top: 75px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: auto;
  max-width: 80vw;
}


.cardContainerLeft {
  flex: 1;
}

.cardContainerRight img {
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}




/* Mobile devices 300px to 400px */
@media (max-width: 400px) {
  .cardContainer {
    flex-direction: column-reverse;
  }
  .cardContainerLeft, .cardContainerRight {
    min-width: 100%;
    text-align: center;
  }
  .cardContainerRight img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    padding: 0px;
    margin: 0px;
    border-radius: 5px 5px 0px 0px;
  }
  .card-container{
    display: block;
    margin: auto;
    width: 100vw;
  }
  .review h1{
    margin-top: 5px;
    font-size: 20px;
    color: #333;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 800;
  }
  .ratingWithStars{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .review p{
    margin: 0;
  }
  .review{
    height: fit-content;
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    line-height: 1.32rem;
    margin-bottom: 30px;
  }
  .cardContainerRight a{
    width: 100%;
    margin: 0 !important;
    border-radius: 0px;
  }


}

/* Mobile devices 400px to 500px */
@media (min-width: 401px) and (max-width: 500px) {
  .cardContainer {
    flex-direction: column-reverse;
  }
  .cardContainerLeft, .cardContainerRight {
    min-width: 100%;
    text-align: center;
  }
  .cardContainerRight img {
    height: 100px;
    width: 100%;
    object-fit: cover;
    padding: 0px;
    margin: 0px;
    border-radius: 5px 5px 0px 0px;
  }
  .card-container{
    display: block;
    margin: auto;
    width: 100vw;
  }
  .review h1{
    margin-top: 5px;
    font-size: 20px;
    color: #333;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: 800;
  }
  .ratingWithStars{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
  .review p{
    margin: 0;
  }
  .review{
    height: 300px;
    width: 100%;
    padding: 0px;
    border-radius: 5px;
    line-height: 1.32rem;
    margin-bottom: 30px;
  }
  .cardContainerRight a{
    width: 100%;
    margin: 0 !important;
    border-radius: 0px;
  }
}

/* Tablets 500px to 800px */
@media (min-width: 501px) and (max-width: 800px) {
  .cardContainer {
    flex-direction: row;
    align-items: flex-start;
  }
  .cardContainerLeft, .cardContainerRight {
    min-width: 200px;
    text-align: left;
  }
  .cardContainerRight img {
    height: 120px;
    width: 150px;
  }
}

/* Desktop */
@media (min-width: 801px) {
  .cardContainer {
    flex-direction: row;
    align-items: flex-start;
  }
  .cardContainerLeft, .cardContainerRight {
    min-width: 200px;
    text-align: left;
  }
  .cardContainerRight img {
    height: 120px;
    width: 150px;
  }
}
 